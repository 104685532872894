import React, { useState } from "react"
import PropTypes from "prop-types"
import AnalyticsService from "../../utils/AnalyticsService"
import { Formik, Form, Field } from "formik"
import { SelectInput, TextInput } from "../../components/forms"
import { Button } from "../../components/styled"
import { FrontDoorOffersSection } from "../FrontDoorOffersSection"
import { SubmissionApi } from "../../utils/api"
import { ErrorMessageStyled } from "../../components/styled"
import { addSchemaValidation } from "../../utils/FormUtils"
import { parseDateString } from "../../utils/Helpers"

export const FrontDoorFaqForm = ({ history, inputPhoneNumber, isTfbOrBau }) => {
    const [hasOffers, setHasOffers] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [offerList, setOfferList] = useState(null);
    const [transactionChannel, setTransactionChannel] = useState("");
    const initialValues = {
        transactionDate: "",
        faq1: "",
        faq2: ""
    }

    var futureMinDate = new Date();
    futureMinDate.setDate(futureMinDate.getDate() - 45);
    let minDate = futureMinDate.toISOString().split('T')[0];

    var futureMaxDate = new Date();
    futureMaxDate.setDate(futureMaxDate.getDate());
    let maxDate = futureMaxDate.toISOString().split('T')[0];

    let faqs = [
        { A1: " T-Mobile Retail Store", value: "1" },
        { A1: "T-Mobile Customer Care", value: "2" },
        { A1: "T-Mobile.com", value: "3" },
        { A1: "1-800-TMOBILE", value: "7"},
        { A1: "Costco", value: "4" },
        { A1: "Sam's Club", value: "5" },
        { A1: "T-Mobile for Business", value: "6" },
    ]
    let faqs2 = [
        { A1: "Add Phone Line - BYOD", value: "1" },
        { A1: "Add Phone Line - New with Trade", value: "2" },
        { A1: "Add Phone Line - New without Trade", value: "3" },
        { A1: "Add Watch/Tablet/Non-Phone - BYOD", value: "4" },
        { A1: "Add Watch/Tablet/Non-Phone - New", value: "5" },
        { A1: "Add Internet - New Router", value: "6" },
        { A1: "Upgrade", value: "7"},
        { A1: "Accessory", value: "8"}
    ]

    const getEligibleOffers = async (event) => {
        AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "submitted", category: "getEligibleOffers", action: isTfbOrBau, label: "continue" })
        setIsSubmitting(true)
        let formatDate = parseDateString(event.transactionDate);
        let dateMDY = `${formatDate.getMonth() + 1}/${formatDate.getDate()}/${formatDate.getFullYear()}`;
        try {
            let frontDoorValues = {
                transactionDate: dateMDY,
                frontDoorChannel: event.faq1,
                frontDoorTransactionType: event.faq2
            };

            await SubmissionApi.getOffers(frontDoorValues).then((response) => {
                if (response.length <= 0 && (frontDoorValues.frontDoorChannel === "4" || frontDoorValues.frontDoorChannel === "5")) {
                    setErrorMessage("");
                    setHasOffers(true);
                    setOfferList([]);
                    setTransactionChannel(frontDoorValues.frontDoorChannel)
                }
                else if(response.length <= 0){
                    setErrorMessage("No offers available");
                    setHasOffers(false);
                }
                else {
                    setErrorMessage("");
                    setHasOffers(true);
                    setOfferList(response);
                    setTransactionChannel(frontDoorValues.frontDoorChannel)
                }
                setIsSubmitting(false);
            },
                (error) => {
                    setErrorMessage("Something went wrong, please try again 1fasdfas");
                    setIsSubmitting(false);
                })
        }
        catch (error) {
            setErrorMessage("Something went wrong, please try again asdfas1");
            setIsSubmitting(false);
        }
    }
    const validationSchema = addSchemaValidation();

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={getEligibleOffers}>
                {({ status }) => (
                    <Form>
                        {<div className="row">
                            <div className="form-group col-md-6">
                                <label className="frontdoor-label-size" >Transaction date*</label>
                                <span className="custom-tooltip">
                                    <i className="fa fa-question-circle magenta frontdoor-label-size"></i>
                                    <div className="tooltip-text"><strong>No receipt?</strong> Log in to your My T-Mobile account and search for your receipt within “View Account Activity” under the “Bill” section.</div>
                                </span>
                                <br />
                                <label className="frontdoor-label-size" >Submission must be initiated within 30 days of activation</label>
                                <Field id="transactionDate" name="transactionDate" mask="mm/dd/2024"
                                    type="date" max={maxDate} min={minDate} component={TextInput} aria-required="true">
                                </Field>
                            </div>
                        </div>}
                        {<div className="row">
                            <div className="form-group col-md-6">
                                <label className="frontdoor-label-size" >Select the Channel of your Transaction*</label>
                                <Field id="faq1" name="faq1" component={SelectInput} aria-required="true">
                                    <option value=""></option>
                                    {faqs.map((faq) => {
                                        return (<option value={faq.value} key={faq.A1}>{faq.A1}</option>)
                                    })}
                                </Field>
                            </div>
                        </div>}
                        {<div className="row">
                            <div className="form-group col-md-6">
                                <label className="frontdoor-label-size">Select your Transaction Type*</label>
                                <Field id="faq2" name="faq2" component={SelectInput} aria-required="true">
                                    <option value=""></option>
                                    {faqs2.map((faq) => {
                                        return (<option value={faq.value} key={faq.A1}>{faq.A1}</option>)
                                    })}

                                </Field>
                            </div>
                        </div>}
                        <Button type="submit" className="btn btn-size" primary  >
                            Continue
                        </Button>
                        {status && status.formError && <p className="error-size">{status.formError}</p>}
                    </Form>)}
            </Formik>
            {hasOffers && !isSubmitting && (<FrontDoorOffersSection isTfbOrBau={isTfbOrBau} inputPhoneNumber={inputPhoneNumber} offerList={offerList} history={history} transactionChannel={transactionChannel} ></FrontDoorOffersSection>)}
            {!isSubmitting && errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
        </>
    )
}

FrontDoorFaqForm.propTypes = {
    history: PropTypes.object,
    inputPhoneNumber: PropTypes.string.isRequired,
    isTfbOrBau: PropTypes.bool
}
