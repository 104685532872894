import * as popTokenBuilderUtil from "./PopTokenGenerator";
import { v4 as uuidv4 } from 'uuid';
import { ROUTE_PATHS } from "../routes";

const axios = require("axios");

const DEFAULT_CONFIG = {
	baseURL: process.env.REACT_APP_API_URL
};

const MEG_CONFIG = {
	clientId: process.env.REACT_APP_MEG_CLIENT_ID,
	baseURL: process.env.REACT_APP_ANONYMOUS_TOKEN_URL,
  };


const AUTH_TOKEN_STORAGE_KEY = "TMO_PROMO_TOKEN_KEY";
const SERVICE_TRANSACTION_ID = "SERVICE_TRANSACTION_ID";
const UI_APP_NAME = "TMO.Promotions.UI";

export default class BaseApi {
	constructor(
		config = DEFAULT_CONFIG, 
		storage = sessionStorage,
		megConfig=MEG_CONFIG) {
		this.config = config;
		this.storage = storage;
		this.httpClient = axios.create({
			baseURL: DEFAULT_CONFIG.baseURL,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		});

		const transId = this.getServiceTransactionID();		

		this.httpClient.interceptors.request.use (
			function (config) {			
				config = popTokenBuilderUtil.generatePopTokenValue(config);
				config.headers.common["ServiceTransactionID"] = transId;
				config.headers.common["HeliosSourceApp"] = UI_APP_NAME;
				return config;
			  },
			  function (error) {
				return Promise.reject(error);
			  }
		);


		this.httpClient.interceptors.response.use(
			(response) => {
				this.setServiceTransactionID(response.headers['servicetransactionid'])
				return response;
			},
			(error) => {
				// handle log in error
				if (
					error.config.url.replace(error.config.baseUrl, "") === "auth-token"
				) {
					return Promise.reject(error);
				}

				if (error.response.status === 401) {
					this.deleteAuthToken();
					this.deleteAnonymousToken();
					this.deleteTMOPromoState();
					window.location.replace(ROUTE_PATHS.HOME);

				}

				return Promise.reject(error);
			}
		);
	}

	getUIAPPName()
	{
		return UI_APP_NAME;
	}

	getServiceTransactionID()
	{
		var transId =  this.storage.getItem(SERVICE_TRANSACTION_ID);
		if (transId === "" || transId === null)
		{
			transId = uuidv4();
			this.storage.setItem(SERVICE_TRANSACTION_ID, transId);
		}
		return transId ;
	}

	setServiceTransactionID(transId) {
		this.storage.setItem(SERVICE_TRANSACTION_ID, transId);
	}

	getAuthToken() {
		return this.storage.getItem(AUTH_TOKEN_STORAGE_KEY);
	}

	setAuthToken(token) {
		this.storage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
	}

	deleteAuthToken() {
		this.storage.removeItem(AUTH_TOKEN_STORAGE_KEY);
	}

	deleteTMOPromoState(){
		this.storage.removeItem("TMO_PROMO_STATE");
	}

	deleteAnonymousToken() {
		this.storage.removeItem("anonymous_token");
	}

	getHeaders(options) {
		const token = this.getAuthToken();

		if (token && !(popTokenBuilderUtil.checkIfAnonymousRequest(options.url))) {
			const Authorization = `Bearer ${token}`;
			return { Authorization, ...this.config.headers };
		} else {
			return this.config.headers;
		}
	}

	callWithHeaders(options) {
		return this.httpClient({
			...options,
			headers: {
				...this.getHeaders(options),
			},
		});
	}

	encodeQueryString(params) {
		return (
			"?" +
			Object.keys(params)
				.map((key) => {
					return `${encodeURIComponent(key)}=${encodeURIComponent(
						params[key]
					)}`;
				})
				.join("&")
		);
	}

	async get(url, data, requestConfig = {}) {
		let urlWithParams = url;

		if (data) {
			urlWithParams = url + `${this.encodeQueryString(data)}`;
		}

		const response = await this.callWithHeaders({
			method: "GET",
			url: urlWithParams,
			...requestConfig,
		});
		return response;
	}

	async post(url, data) {
		const response = await this.callWithHeaders({ method: "POST", url, data });
		return response;
	}
	async put(url, data) {
		const response = await this.callWithHeaders({ method: "PUT", url, data });
		return response;
	}
	async patch(url, data) {
		const response = await this.callWithHeaders({ method: "PATCH", url, data });
		return response;
	}
	async delete(url, data) {
		const response = await this.callWithHeaders({
			method: "DELETE",
			url,
			data,
		});
		return response;
	}
}
