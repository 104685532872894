export const ROUTE_PATHS = {
  HOME: "/",
  ORDERSEARCH: "/OrderSearch",
  OFFERSEARCH: "/OfferSearch",
  TVONUS: "/TVOnUs",
  DASHBOARD: "/rebates",
  PROMOTION: "/promotion",
  SUBMISSION_METHOD: "/method",
  REGISTER: "/register",
  UPLOAD: "/upload",
  THANKS: "/thanks",
  CONTACT: "/contact",
  CONTACT_SUCCESS: "/contact_success",
  STATUS: "/status",
  STATUS_DETAIL: "/status/:id",
  FAQ: "/faq",
  FIRSTRESPONDER: "/FirstResponder",
  SHEERIDIFRAME: "/Verification",
  APPLETV: "/ApplehideTV",
  APPLEFITNESS: "/Apple",
  PHILO: "/philo",
  OOMA: "/ooma",
  AAA: "/AAA",
  GOOGLE: "/google",
  SAMSCLUB: "/samsclub",
  COSTCO: "/costco",
  FRONTDOORFAQ: "/frontDoorForm",
  GETTFBMSISDN: "/getTFBMsisdn",
  SUBMISSION_STATUS: "/submissionstatus",
  PROMO_CODE_PAGE: "/promoCode"
}
